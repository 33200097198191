import { defineStore } from "pinia"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { AnalyticsEvent, ShareVisibility } from "@evercam/shared/types"
import {
  Share,
  ShareOwner,
  SharePermission,
  ShareRequest,
  ShareType,
} from "@evercam/shared/types/shares"
import moment from "moment-timezone"
import { useProjectStore } from "@/stores/project"
import { useCameraStore } from "@/stores/camera"
import { useAccountStore } from "@/stores/account"
import { useNuxtApp } from "#app"

const getCameraPermission = function (rights) {
  if (rights.indexOf("edit") !== -1) {
    return SharePermission.Full
  } else if (rights.indexOf("share") !== -1) {
    return SharePermission.Share
  }

  return SharePermission.Minimum
}

const generateRightList = function (permissions) {
  let rights = ["list", "view", "snapshot"]

  if (permissions === SharePermission.Share) {
    rights = [...rights, "share"]
  } else if (permissions === SharePermission.Full) {
    rights = [...rights, "share", "edit"]
  }

  return rights.join(",")
}

interface CreateShare {
  email: string[] | []
  message: string
  rights: string
  permission: SharePermission
}

interface ExtendedShareRequest extends ShareRequest {
  currentUserCanChangeRights?: boolean
  permission: SharePermission
  type: ShareType
}

interface ExtendedShare extends Share {
  currentUserCanChangeRights?: boolean
  permission: SharePermission
  type: ShareType
}
type ShareObject =
  | ExtendedShare
  | ExtendedShareRequest
  | (ShareOwner & { isOwner: true })
interface ShareState {
  shareObject: Array<ShareObject>
  shares: Array<ExtendedShare>
  shareRequests: Array<ExtendedShareRequest>
  owner: ShareOwner | null
  ownerTransferDialog: boolean
  ownerTransferLoading: boolean
  newOwner: ShareOwner | string | null
  visibilityDialog: boolean
  sharesLoading: boolean
  visibility: string
  addUserDialog: boolean
  selectedShare: ExtendedShareRequest | null
  selectedShareToUpdate: any
  createdShare: CreateShare
  defaultCreatedShare: CreateShare
  createShareLoading: boolean
}

export const useShareStore = defineStore({
  id: "share",
  state: (): ShareState => ({
    shareObject: [],
    shares: [],
    shareRequests: [],
    owner: null,
    sharesLoading: false,
    ownerTransferDialog: false,
    ownerTransferLoading: false,
    newOwner: null,
    visibilityDialog: false,
    visibility: "",
    addUserDialog: false,
    selectedShare: null,
    selectedShareToUpdate: {},
    createdShare: {
      email: [],
      message: "",
      rights: "",
      permission: SharePermission.Minimum,
    },
    defaultCreatedShare: {
      email: [],
      message: "",
      rights: "",
      permission: SharePermission.Minimum,
    },
    createShareLoading: false,
  }),
  actions: {
    async updateShareObject() {
      this.shareObject = [...this.shares, ...this.shareRequests].sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      )
      this.shareObject = [{ ...this.owner, isOwner: true }, ...this.shareObject]

      if (!useAccountStore().email.includes("@evercam.io")) {
        this.shareObject = this.shareObject.filter(
          ({ email }) => email !== "demo@evercam.io"
        )
      }
    },
    async fetchShareRequests(cameraId) {
      this.sharesLoading = true
      const requests = await EvercamApi.shares.getShareRequest(cameraId, {
        status: "PENDING",
      })
      let permission = ""
      this.shareRequests = requests.shareRequests.reduce((acc, request) => {
        permission = getCameraPermission(request.rights)

        return [
          ...acc,
          {
            ...request,
            permission: permission,
            createdAt: moment(request.createdAt).unix(),
            type: "request",
            currentUserCanChangeRights:
              useAccountStore().email === request.email &&
              permission === SharePermission.Share,
          },
        ]
      }, [])
      this.sharesLoading = false
    },
    async fetchShares(cameraId) {
      this.sharesLoading = true
      const { shares, owner } = await EvercamApi.shares.cIndex(cameraId)
      let permission = ""
      this.shares = shares.reduce((acc, share) => {
        permission = getCameraPermission(share.rights)

        return [
          ...acc,
          {
            ...share,
            permission: permission,
            createdAt: moment(share.createdAt).unix(),
            type: "share",
            currentUserCanChangeRights:
              useAccountStore().email === share.email &&
              permission === SharePermission.Share,
          },
        ]
      }, [])
      this.owner = owner
      this.sharesLoading = false
    },
    async transferOwnership(cameraId) {
      useNuxtApp().nuxt2Context.$analytics.saveEvent(
        AnalyticsEvent.sharingTransferOwnership
      )
      this.ownerTransferDialog = true
      try {
        const owner = this.newOwner as Share
        let payload = {
          userId: owner?.userId,
        }
        await EvercamApi.shares.transferOwnership(cameraId, payload)

        await this.fetchShares(cameraId)
        await this.updateShareObject()

        useNuxtApp().nuxt2Context.$notifications.success(
          "Camera ownership has been successfully transferred."
        )
        this.newOwner = null
        await useCameraStore().fetchUserCameras()
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: useNuxtApp().vue2App.$i18n.t(
            "content.shares.transfer_camera_failed"
          ) as string,
          error,
        })
      } finally {
        this.ownerTransferLoading = false
        this.ownerTransferDialog = false
      }
    },
    async switchVisibility(cameraExid) {
      try {
        let payload = {
          isPublic: [
            ShareVisibility.PublicDiscoverable,
            ShareVisibility.PublicUndiscoverable,
          ].includes(this.visibility as ShareVisibility),
          discoverable: this.visibility === ShareVisibility.PublicDiscoverable,
        }
        await EvercamApi.cameras.updateCamera(cameraExid, payload)
        useNuxtApp().nuxt2Context.$analytics.saveEvent(
          AnalyticsEvent.sharingSaveVisibilityOptions,
          payload
        )
        this.visibilityDialog = false
        useNuxtApp().nuxt2Context.$notifications.success(
          "Camera permissions successfully updated."
        )
        await useCameraStore().fetchUserCameras()
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: useNuxtApp().vue2App.$i18n.t(
            "content.shares.switch_visibility_failed"
          ) as string,
          error,
        })
      }
    },
    async deleteShareOrShareRequest(cameraId) {
      const projectStore = useProjectStore()
      let { email, id, type } = this.selectedShare
      try {
        let payload = {}
        if (type === "request") {
          payload = {
            params: {
              email,
              key: id,
              origin: "dashboard",
            },
          }
          await EvercamApi.shares.deleteRequest(cameraId, payload)
          useNuxtApp().nuxt2Context.$analytics.saveEvent(
            AnalyticsEvent.sharingDeleteUser
          )
          this.shareRequests = this.shareRequests.filter(
            (request) => request.id !== id
          )
        } else {
          payload = {
            params: { email },
          }
          await EvercamApi.shares.deleteShare(cameraId, payload)
          this.shares = this.shares.filter((share) => share.id !== id)
        }
        useNuxtApp().nuxt2Context.$notifications.success(
          useNuxtApp().vue2App.$i18n.t(
            "content.shares.delete_share_success"
          ) as string
        )
        if (email === useAccountStore().email) {
          await projectStore.fetchUserProjects()
          await useCameraStore().fetchUserCameras()
          if (projectStore.selectedProject?.camerasCount === 1) {
            useNuxtApp().nuxt2Context.app.router.push("/v2/projects")
          } else {
            useNuxtApp().nuxt2Context.app.router.push(projectStore.projectRoute)
          }
        }
        await this.updateShareObject()
      } catch (error) {
        const typeShare = type === "request" ? "share_request" : "share"
        useNuxtApp().nuxt2Context.$notifications.error({
          text: useNuxtApp().vue2App.$i18n.t(
            `content.shares.delete_${typeShare}_failed`
          ) as string,
          error,
        })
      } finally {
        this.selectedShare = null
      }
    },
    async resendShareRequest({ cameraId, email }) {
      try {
        useNuxtApp().nuxt2Context.$analytics.saveEvent(
          AnalyticsEvent.sharingResendInvite
        )
        await EvercamApi.shares.resendShareRequest(cameraId, {
          email,
        })
        useNuxtApp().nuxt2Context.$notifications.success(
          useNuxtApp().vue2App.$i18n.t("content.shares.resend_share_request")
        )
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: useNuxtApp().vue2App.$i18n.t(
            "content.shares.resend_failed"
          ) as string,
          error,
        })
      }
    },
    async updateShare(cameraId) {
      const updateRights =
        this.selectedShareToUpdate.type === "request"
          ? EvercamApi.shares.updateShareRequestRights
          : EvercamApi.shares.updateShareRights

      try {
        await updateRights(cameraId, {
          email: this.selectedShareToUpdate.email,
          rights: generateRightList(
            this.selectedShareToUpdate.permission.value
          ),
        })
        const rights = {
          [SharePermission.Minimum]: "Read Only",
          [SharePermission.Share]: "Read + Share",
          [SharePermission.Full]: "Full Rights",
        }
        useNuxtApp().nuxt2Context.$analytics.saveEvent(
          AnalyticsEvent.sharingChangeAccessRight,
          { access: rights[this.selectedShareToUpdate.permission.value] }
        )
        useNuxtApp().nuxt2Context.$notifications.success(
          useNuxtApp().vue2App.$i18n.t("content.shares.rights_updated")
        )
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: useNuxtApp().vue2App.$i18n.t(
            "content.shares.update_rights_failed"
          ) as string,
          error,
        })
      } finally {
        this.selectedShareToUpdate = {}
      }
    },
    async createShare(cameraId, notify = true) {
      this.createShareLoading = true
      this.createdShare = {
        ...this.createdShare,
        email: this.createdShare.email.filter(
          (email) =>
            !this.shareObject.map((share) => share.email).includes(email)
        ),
      }

      if (!this.createdShare.email.length) {
        useNuxtApp().nuxt2Context.$notifications.success(
          useNuxtApp().vue2App.$i18n.t(
            "content.shares.already_shared"
          ) as string
        )
        this.createShareLoading = false

        return
      }

      this.createShareLoading = true
      try {
        let payload = {
          ...this.createdShare,
          rights: generateRightList(this.createdShare.permission),
          notify,
        }
        const { shares, shareRequests } = await EvercamApi.shares.cCreate(
          cameraId,
          payload
        )

        if (cameraId !== useCameraStore().selectedCameraExid) {
          return
        }
        this.shares = [
          ...this.shares,
          ...shares.map((share) => ({
            ...share,
            permission: this.createdShare.permission,
            createdAt: moment(share.createdAt).unix(),
            type: ShareType.Share,
          })),
        ]

        this.shareRequests = [
          ...this.shareRequests,
          ...shareRequests.map((request) => ({
            ...request,
            permission: this.createdShare.permission,
            createdAt: moment(request.createdAt).unix(),
            type: ShareType.Request,
          })),
        ]
        await this.updateShareObject()

        const formattedEmailsList = this.createdShare.email.join(", ")

        useNuxtApp().nuxt2Context.$notifications.success(
          `${useNuxtApp().vue2App.$i18n.t(
            "content.shares.share_success"
          )} ${formattedEmailsList}.`
        ) as unknown as string
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: useNuxtApp().vue2App.$i18n.t(
            "content.shares.create_failed"
          ) as string,
          error,
        })
      } finally {
        this.createdShare = { ...this.defaultCreatedShare }
        this.createShareLoading = false
      }
    },
  },
})
